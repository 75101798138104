import React from 'react';
import PropTypes from 'prop-types';

import { StyleSheet, css } from 'aphrodite';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import Table from 'react-bootstrap/Table';

import { adPreviewPath, adUpgradePath, adUpgradeSynthesizedAiOnlyPath } from '~/helpers/app/paths';

import WithIcon from '~/components/app/common/WithIcon';
import moment from 'moment-timezone';
import { DATE_FORMAT } from '~/components/app/live_auto_captioning/common/constants';
import ThreePlayTooltip from '~/components/app/common/ThreePlayTooltip';
import { userLogger } from '~/logic/UserLogger';

interface Service {
  id: string;
  type: string;
  audioDescriptionId: string;
  status: 'pending' | 'in_progress' | 'complete' | 'cancelled' | 'rejected';
  level: string;
  autoExtend: boolean;
  speaker?: string;
  language?: {
    name: string;
  };
  speakingRate?: string;
  deadline: string;
  turnaroundLevel: string;
  orderedAt: string;
  canUpgrade: boolean;
  solutionType: string;
}

interface FileData {
  id: string;
  reprocessingAssets?: string[];
  assetsRemoved?: boolean;
}

interface ShowModalProps {
  downloadAd: (show: boolean, audioDescriptionId: string) => void;
}

interface AudioDescriptionServiceCardContentsProps {
  services: Service[];
  fileData: FileData;
  setShowModal: ShowModalProps;
  userTimeZone: string;
}

function AudioDescriptionServiceCardContents({
  services,
  fileData,
  setShowModal,
  userTimeZone,
}: AudioDescriptionServiceCardContentsProps) {
  const assetReprocessing = fileData?.reprocessingAssets?.length ?? 0 > 0;
  const assetsRemoved = fileData?.assetsRemoved;
  const tooltipText = assetsRemoved
    ? 'Downloading is disabled for files with scrubbed content'
    : null;
  const downloadButton = assetsRemoved ? (
    <OverlayTrigger
      placement="auto"
      overlay={<Tooltip id="download-tooltip">{tooltipText}</Tooltip>}
    >
      <span>
        <WithIcon icon="fa fa-download">{''}</WithIcon>
      </span>
    </OverlayTrigger>
  ) : (
    <WithIcon icon="fa fa-download">{''}</WithIcon>
  );

  function serviceIsVoiceArtist(service: Service): boolean {
    return (
      service.type === 'AudioDescriptionNarrationService' ||
      service.type === 'AudioDescriptions::VoiceOvers::VoiceOverAudioDescriptionService' ||
      service.type === 'AudioDescriptions::Reformats::AudioDescriptionReformatService' ||
      service.type === 'AudioDescriptions::Revisions::AudioDescriptionRevisionService'
    );
  }

  function voiceDisplayText(service: Service): string {
    return `${service?.speaker ?? ''} (${service.language?.name ?? ''}, ${service.speakingRate ?? ''})`;
  }

  function downloadClick(service: Service): void {
    if (!assetsRemoved) {
      setShowModal.downloadAd(true, service.audioDescriptionId);

      // @ts-expect-error Expected error due to userLogger not being typed
      userLogger.logEvent('FileShow', 'AD Download Button');
    }
  }

  const showPreviewColumn = (service: Service): boolean => {
    return !serviceIsVoiceArtist(service) && service.status === 'complete' && !assetReprocessing;
  };

  const showDownloadColumn = (service: Service): boolean => {
    return service.status === 'complete' && !assetReprocessing;
  };

  const showUpgradeColumn = (service: Service): boolean => {
    return service.status === 'complete' && !assetReprocessing && service.canUpgrade;
  };

  const renderPreviewColumn = (service: Service) => {
    return (
      <a
        href={adPreviewPath(service.audioDescriptionId)}
        target="blank"
        className={css(styles.serviceAction)}
        title="Preview Audio Description"
      >
        <FontAwesomeIcon icon={faPencilAlt} />
      </a>
    );
  };

  const renderDownloadColumn = (service: Service) => {
    return (
      <a
        href={assetsRemoved ? undefined : '#'}
        onClick={() => downloadClick(service)}
        title="Download Audio Description"
      >
        {downloadButton}
      </a>
    );
  };

  const renderUpgradeColumn = (service: Service) => {
    if (service.solutionType === 'AI Only') {
      return (
        <a
          href={adUpgradeSynthesizedAiOnlyPath(fileData.id, service.id)}
          key="upgrade-ad"
          className={css(styles.serviceAction)}
        >
          Upgrade
        </a>
      );
    }

    return (
      <a
        href={adUpgradePath(fileData.id, service.id)}
        key="upgrade-ad"
        className={css(styles.serviceAction)}
      >
        Upgrade
      </a>
    );
  };

  const renderInProgressTooltip = (service: Service) => {
    return (
      <ThreePlayTooltip
        tooltipText={`Due Date: ${moment(parseInt(service.deadline))
          .tz(userTimeZone)
          .format(DATE_FORMAT)}\nTurnaround: ${service.turnaroundLevel}`}
        appearInTabNavigation
      />
    );
  };

  const renderOrderedTime = (service: Service) => {
    const formattedDate = moment(parseInt(service.orderedAt)).tz(userTimeZone).format(DATE_FORMAT);

    switch (service.status) {
      case 'pending':
        return `Ordered ${formattedDate}`;
      case 'in_progress':
        return (
          <>
            Ordered {formattedDate}
            {renderInProgressTooltip(service)}
          </>
        );
      case 'complete':
        if (assetReprocessing) return <i>Processing</i>;
        return `Completed ${formattedDate}`;
      case 'cancelled':
        return 'Cancelled';
      case 'rejected':
        return 'Rejected';
      default:
        return formattedDate;
    }
  };

  const showVoiceColumnHeader = services.some((service) => !serviceIsVoiceArtist(service));
  const showPreviewColumnHeader = services.some(showPreviewColumn);
  const showDownloadColumnHeader = services.some(showDownloadColumn);
  const showUpgradeColumnHeader = services.some(showUpgradeColumn);

  return (
    <Table size="sm">
      <thead>
        <tr>
          <th>Solution</th>
          <th>Level</th>
          {showVoiceColumnHeader && <th>Voice</th>}
          <th>Status</th>
          {showPreviewColumnHeader && <th></th>}
          {showDownloadColumnHeader && <th></th>}
          {showUpgradeColumnHeader && <th></th>}
        </tr>
      </thead>
      <tbody>
        {services.map((service) => (
          <tr key={service.id}>
            <td>{service.solutionType}</td>
            <td>{service.level + (service.autoExtend ? ' (3Play Recommended)' : '')}</td>
            {!serviceIsVoiceArtist(service) && <td>{voiceDisplayText(service)}</td>}
            <td>{renderOrderedTime(service)}</td>
            {showDownloadColumn(service) && <td>{renderDownloadColumn(service)}</td>}
            {showUpgradeColumn(service) && <td>{renderUpgradeColumn(service)}</td>}
            {showPreviewColumn(service) && <td>{renderPreviewColumn(service)}</td>}
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

AudioDescriptionServiceCardContents.propTypes = {
  services: PropTypes.arrayOf(PropTypes.object),
  fileData: PropTypes.shape({
    id: PropTypes.string,
    reprocessingAssets: PropTypes.arrayOf(PropTypes.string),
    assetsRemoved: PropTypes.bool,
  }),
  setShowModal: PropTypes.shape({
    downloadAd: PropTypes.func,
  }),
  userTimeZone: PropTypes.string,
};

const styles = StyleSheet.create({
  serviceAction: {
    color: '#007EB5',
    cursor: 'pointer',
    fontWeight: 'bold',
  },
  noPadding: {
    paddingLeft: '0px',
    paddingRight: '0em',
  },
  rightPadding: {
    paddingLeft: '0px',
    paddingRight: '0.5em',
  },
});

export default AudioDescriptionServiceCardContents;
