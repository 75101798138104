import React from 'react';
import PropTypes from 'prop-types';

import BasicServiceInfo from './BasicServiceInfo';

function AutomatedCaptionPlacementServiceCard(props) {
  return (
    <BasicServiceInfo
      service={props.service}
      showOrderedAt
      showComplete
      userTimeZone={props.userTimeZone}
    />
  );
}

AutomatedCaptionPlacementServiceCard.propTypes = {
  service: PropTypes.object,
  userTimeZone: PropTypes.string,
};

export default AutomatedCaptionPlacementServiceCard;
