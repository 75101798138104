import React from 'react';
import { useMultipleMediaPrices } from '../hooks/useMultipleMediaPrices';
import { OrderOptions } from '~/components/app/order_form/serviceOptions/AudioDescriptionOrderOptions';

export interface MediaFile {
  id: number;
  name: string;
  duration: number; // in ms
  languageId: number;
}

interface MediaFileSummaryProps {
  files: MediaFile[];
  authenticityToken?: string;
  serviceDetails?: OrderOptions;
}

const formatDuration = (durationMs: number): string => {
  const totalSeconds = Math.floor(durationMs / 1000);
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;

  return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
};

const renderPrice = (price: number | null | undefined): string => {
  if (price === null || price === undefined) return 'Calculating...';
  return `$${price.toFixed(2)}`;
};

const MediaFileSummary = ({ files, authenticityToken, serviceDetails }: MediaFileSummaryProps) => {
  const { filePrices, totalPrice, isLoading } = useMultipleMediaPrices(
    files,
    authenticityToken,
    serviceDetails
  );

  if (!files || files.length === 0) {
    return <div className="empty-state">No media files available</div>;
  }

  const totalDurationMs = files.reduce((sum, file) => sum + file.duration, 0);

  return (
    <div className="media-file-summary pb-2">
      <table className="table">
        <thead>
          <tr>
            <th>File Name</th>
            <th>Duration</th>
            {authenticityToken && serviceDetails && <th>Expected Price</th>}
          </tr>
        </thead>
        <tbody>
          {files.map((file) => (
            <tr key={file.id}>
              <td>{file.name}</td>
              <td>{formatDuration(file.duration)}</td>
              {authenticityToken && serviceDetails && (
                <td>{isLoading ? '' : renderPrice(filePrices.get(file.id)?.price)}</td>
              )}
            </tr>
          ))}
          <tr className="table-active">
            <td>
              <strong>Total</strong>
            </td>
            <td>
              <strong>{formatDuration(totalDurationMs)}</strong>
            </td>
            {authenticityToken && serviceDetails && (
              <td>
                <strong>{isLoading ? '' : renderPrice(totalPrice)}</strong>
              </td>
            )}
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default MediaFileSummary;
