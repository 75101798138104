import { Controller } from '@hotwired/stimulus';
import { DirectUpload } from '@rails/activestorage';

export default class extends Controller {
  static targets = ['input', 'progressBar'];
  static values = { url: String };

  now() {
    Array.from(this.inputTarget.files).forEach((file) => this.uploadFile(file));
  }

  uploadFile(file) {
    new DirectUpload(
      file,
      this.urlValue,
      this // callback directUploadWillStoreFileWithXHR(request)
    ).create((error, blob) => {
      if (error) {
        console.log('Upload error: ', error);
      } else {
        console.log('Uploaded successfully: ', file);
      }
    });
  }

  directUploadWillStoreFileWithXHR(request) {
    request.upload.addEventListener('progress', (event) => {
      this.progressUpdate(event);
    });
    request.addEventListener('load', () => {
      this.directUploadDidSucceed();
    });
  }

  progressUpdate(event) {
    const progress = (event.loaded / event.total) * 100;

    this.progressBarTargets.forEach((progressBar) => {
      const progressBarElement = progressBar.querySelector('.progress-bar');
      if (progressBarElement) {
        progressBarElement.style.width = `${Math.round(progress)}%`;
      }
    });
  }

  directUploadDidSucceed() {
    this.progressBarTargets.forEach((progressBar) => {
      const progressBarElement = progressBar.querySelector('.progress-bar');
      if (progressBarElement) {
        // TODO: This is slightly clumsy and does not allow for customization of success behavior / color.
        progressBarElement.classList.remove('bg-primary');
        progressBarElement.classList.remove('bg-info');
        progressBarElement.classList.add('bg-success');
      }
    });
  }
}
