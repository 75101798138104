import React from 'react';

interface SpeakerSettings {
  speakerName: string;
  language: string;
  speakingRate: string;
  settingsUrl: string;
}

interface SpeakerSettingsProps {
  settings: SpeakerSettings;
}

const SpeakerSettings = ({ settings }: SpeakerSettingsProps) => {
  if (!settings) {
    return null;
  }

  const { speakerName, language, speakingRate, settingsUrl } = settings;

  return (
    <div>
      <h3>Speaker Settings</h3>
      <p>
        <b>{language} Speaker:</b> {speakerName}
        <br />
        <b>{language} Speaking Rate:</b> {speakingRate}
      </p>
      <a href={settingsUrl}>Change speaker settings</a>
    </div>
  );
};

export default SpeakerSettings;
