import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';

import ServiceStatus from './ServiceStatus';
import WithIcon from '~/components/app/common/WithIcon';

import ProgressBar from './ProgressBar';

function ServiceCardHeader(props) {
  /**
   * Note this functionality is gated behind both a Flipper flag and a Resource Configuration
   * If the Flipper flag `get_it_now` is not enabled for the account, this method will return
   * false and the button will not display on the UI.
   * If the Resource Configuration `can_force_finalize_transcription_early?` is not enabled,
   * the button will be displayed, but some functionality may not work. Note, however, that
   * this will not leave data in a bad state, it will simply no-op.
   */
  function canGetItNow() {
    return (
      props.showGetItNow &&
      props.serviceType === 'Transcription and Captioning' &&
      props.services[0].status === 'in_progress'
    );
  }

  const hasReformatService = props.services.some((service) => service.reformat === true);

  function handleGetItNow() {
    props.setShowModal.getItNow(true, props.services[0]);
  }

  function handleShowCancelModal() {
    if (!props.cancellable) {
      return;
    }
    props.setShowModal.cancelService(true, props.services);
  }

  function maybeAppendReformat(serviceName, hasReformatService) {
    if (hasReformatService) {
      return serviceName.concat(' Reformat');
    }
    return serviceName;
  }

  function serviceName(serviceType) {
    switch (serviceType) {
      case 'Transcription and Captioning':
        if (props.enabledFeatures && !props.enabledFeatures.includes('other')) {
          return 'Transcription';
        }
        return 'Transcription and Captioning';
      case 'Audio Description Narration':
      case 'Voice Artist Audio Description':
      case 'Audio Description':
        return 'Audio Description';
      default:
        return serviceType;
    }
  }

  function showProgressBar(serviceType, showProgressBar) {
    return serviceType === 'Transcription and Captioning' && showProgressBar;
  }

  function getProgressBarMessage(percentComplete) {
    if (percentComplete >= 80 && percentComplete < 100) {
      return 'Editing Complete';
    } else {
      return null;
    }
  }

  return (
    <div>
      <h5 className={css(styles.displayType)}>
        {maybeAppendReformat(serviceName(props.serviceType), hasReformatService)}
      </h5>
      {props.serviceCount && <span className={css(styles.serviceCount)}>{props.serviceCount}</span>}
      <ServiceStatus status={props.status} />
      <div className={css(styles.floaters)}>
        {props.actions?.map((item, ii) => (
          <span key={ii} className={css(styles.serviceAction)}>
            {item}
          </span>
        ))}
        {canGetItNow(props.serviceType) && (
          <span className={css(styles.serviceAction)} onClick={handleGetItNow}>
            <WithIcon icon="fa fa-regular fa-download">Get It Now</WithIcon>
          </span>
        )}
        {props.cancellable && (
          <span
            className={css(styles.serviceAction, styles.cancelButton)}
            onClick={handleShowCancelModal}
          >
            <WithIcon side="closeLeft" icon="fa fa-times-circle">
              Cancel
            </WithIcon>
          </span>
        )}
        <button
          aria-controls={`serviceCard-${props.randomId}`}
          aria-expanded={props.open ? true : false}
          className={`${css(styles.serviceAction, styles.showHideButton)} border-0 bg-transparent`}
          onClick={() => props.setOpen(!props.open)}
        >
          <i className={props.open ? 'fa fa-minus' : 'fa fa-plus'} aria-hidden="true" /> Details
        </button>
      </div>
      {showProgressBar(props.serviceType, props.showProgressBar) && (
        <ProgressBar
          completed={props.percentComplete}
          message={getProgressBarMessage(props.percentComplete)}
        />
      )}
    </div>
  );
}

ServiceCardHeader.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.node),
  cancellable: PropTypes.bool,
  enabledFeatures: PropTypes.arrayOf(PropTypes.string),
  open: PropTypes.bool,
  percentComplete: PropTypes.number,
  randomId: PropTypes.number,
  serviceCount: PropTypes.number,
  serviceType: PropTypes.string,
  status: PropTypes.string,
  setShowModal: PropTypes.shape({
    cancelService: PropTypes.func,
    getItNow: PropTypes.func,
  }),
  setOpen: PropTypes.func,
  services: PropTypes.arrayOf(PropTypes.object),
  showGetItNow: PropTypes.bool,
  showProgressBar: PropTypes.bool,
};

const styles = StyleSheet.create({
  cancelButton: {
    color: '#b10000',
  },
  displayType: {
    display: 'inline-block',
    margin: '3px 0px',
    fontSize: '17px',
    fontWeight: 'bold',
  },
  floaters: {
    display: 'inline-block',
    float: 'right',
    marginTop: '3px',
  },
  serviceAction: {
    color: '#007EB5',
    cursor: 'pointer',
    fontWeight: 'bold',
    paddingLeft: '1em',
  },
  showHideButton: {
    color: '#007EB5',
  },
  serviceCount: {
    backgroundColor: '#999',
    color: '#fff',
    fontSize: '0.8rem',
    fontWeight: 'bold',
    marginLeft: '0.3rem',
    padding: '0 4px',
    borderRadius: '1em',
  },
});

export default ServiceCardHeader;
