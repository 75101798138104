import { useState, useEffect } from 'react';
import { MediaFile } from '../components/MediaFileSummary';
import { OrderOptions } from '~/components/app/order_form/serviceOptions/AudioDescriptionOrderOptions';
import { PriceInfo, ServiceDetails } from './useMediaPrices';

export interface MultipleMediaPricesResult {
  filePrices: Map<number, PriceInfo>;
  totalPrice: number | null;
  isLoading: boolean;
  error: Error | null;
}

interface MultiPriceResponse {
  file_id: number;
  price: number | null;
  pricerate: number;
  target_language?: number;
  service_level_id?: string;
  vendor_id?: number;
}

export type MultipleServiceDetails = Omit<ServiceDetails, 'languages'>;

const getServiceDetails = (payload: OrderOptions): string => {
  return payload?.speakerType?.name === 'Voice Artist'
    ? 'AudioDescriptions::VoiceOvers::VoiceOverAudioDescription'
    : 'AudioDescription';
};

// Hook for multiple files price calculation
export function useMultipleMediaPrices(
  files: MediaFile[],
  authenticityToken?: string,
  serviceDetails?: OrderOptions
): MultipleMediaPricesResult {
  const [filePrices, setFilePrices] = useState<Map<number, PriceInfo>>(new Map());
  const [totalPrice, setTotalPrice] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const calculatePrices = async () => {
      if (!files || files.length === 0 || !authenticityToken || !serviceDetails) return;

      setIsLoading(true);
      setError(null);

      try {
        const data = new FormData();
        data.append('authenticity_token', authenticityToken);

        files.forEach((file) => {
          data.append('fileIds[]', file.id.toString());
        });

        const apiPayload: MultipleServiceDetails[] = [
          {
            serviceType: getServiceDetails(serviceDetails),
            serviceLevel: serviceDetails.serviceLevel?.name || '',
            turnaroundLevel: serviceDetails.turnaroundLevel?.id
              ? Number(serviceDetails.turnaroundLevel.id)
              : 0,
            orderOption: serviceDetails.speakerType
              ? { speakerType: serviceDetails.speakerType }
              : {},
          },
        ];

        data.append('serviceDetails', JSON.stringify(apiPayload));

        const response = await fetch('/service_pricing/compute_multiple', {
          method: 'POST',
          body: data,
        });

        const priceData = (await response.json()) as MultiPriceResponse[];

        const newPrices = new Map<number, PriceInfo>();
        let totalPriceValue = 0;

        priceData.forEach((item) => {
          const priceInfo = {
            price: item.price,
            pricerate: item.pricerate,
          };

          newPrices.set(item.file_id, priceInfo);

          if (item.price !== null) {
            totalPriceValue += item.price;
          }
        });

        setFilePrices(newPrices);
        setTotalPrice(totalPriceValue);
      } catch (err) {
        setError(err instanceof Error ? err : new Error('Unknown error calculating prices'));
        console.error('Error calculating prices:', err);
      } finally {
        setIsLoading(false);
      }
    };

    void calculatePrices();
  }, [files, authenticityToken, serviceDetails]);

  return { filePrices, totalPrice, isLoading, error };
}
