import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { css } from 'aphrodite';
import { Card, Form } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import { isEmpty } from 'lodash';
import { appStyles } from '~/components/app/common/styles';
import {
  selectedServicesType,
  subtitlingOrderOptionsType,
  translationProfileType,
} from '~/components/app/order_form/propTypes';
import OrderOptionsContainer from '~/components/app/order_form/serviceOptions/OrderOptionsContainer';
import SubtitlingOrderOptionsTable from '~/components/app/order_form/serviceOptions/subtitling/SubtitlingOrderOptionsTable';

const SubtitlingOrderOptions = ({
  disablePreprocessingOptions,
  localizationProfiles,
  selectedServices,
  skipContainer,
  subtitlingOrderOptions,
  translationProfiles,
  updateOrderOptions,
}) => {
  const selectedOrderOptions = selectedServices.find((service) => service.serviceType === 'Subtitling')?.orderOptions;
  const subtitlingOrderData =
    isEmpty(selectedOrderOptions) ? {
      localizationProfileId: localizationProfiles[0]?.id,
    } : selectedOrderOptions;
  const selectedSubtitlingOrderOptions = subtitlingOrderData?.selectedSubtitlingOptions || [];
  const firstOptionPerLanguage = useMemo(() => {
    const languages = {};
    subtitlingOrderOptions.forEach((option) => {
      if (!languages[option.targetLanguage.fullName]) {
        languages[option.targetLanguage.fullName] = option;
      }
    });
    return Object.values(languages).sort((a, b) =>
      a.targetLanguage.fullName.localeCompare(b.targetLanguage.fullName)
    );
  }, [subtitlingOrderOptions]);

  const Wrapper = skipContainer ? React.Fragment : OrderOptionsContainer;
  const wrapperProps = skipContainer
    ? {}
    : { title: 'Subtitling', componentName: 'SubtitlingOrderOptions' };

  return (
    <Wrapper {...wrapperProps}>
      <Form.Group>
        <Card.Title className={css(appStyles.title)}>
          What type of subtitling do you need?
        </Card.Title>
        <Form.Control
          as="select"
          className={css(appStyles.selector)}
          disabled={disablePreprocessingOptions || false}
          onChange={(e) =>
            updateOrderOptions('Subtitling', { ...subtitlingOrderData, sdh: e.target.value })
          }
          value={subtitlingOrderData?.sdh ?? false}
        >
          <option value={true}>SDH Subtitles</option>
          <option value={false}>Non-SDH Subtitles</option>
        </Form.Control>
      </Form.Group>
      <Form.Group>
        <Card.Title className={css(appStyles.title)}>Do you need on-screen text?</Card.Title>
        <Form.Control
          as="select"
          className={css(appStyles.selector)}
          disabled={disablePreprocessingOptions || false}
          onChange={(e) =>
            updateOrderOptions('Subtitling', {
              ...subtitlingOrderData,
              includeOnScreenText: e.target.value,
            })
          }
          value={subtitlingOrderData?.includeOnScreenText ?? false}
        >
          <option value={true}>Include On-Screen Text</option>
          <option value={false}>Exclude On-Screen Text</option>
        </Form.Control>
      </Form.Group>
      {(localizationProfiles?.length ?? 0) > 0 && (
        <Form.Group>
          <Card.Title className={css(appStyles.title)}>Localization Profile</Card.Title>
          <Form.Control
            as="select"
            className={css(appStyles.selector)}
            disabled={disablePreprocessingOptions || false}
            onChange={(e) =>
              updateOrderOptions('Subtitling', {
                ...subtitlingOrderData,
                localizationProfileId: e.target.value,
              })
            }
            value={subtitlingOrderData?.localizationProfileId ?? false}
          >
            {localizationProfiles.map((profile) => (
              <option key={profile.id} value={profile.id}>
                {profile.name}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
      )}

      <Card.Title className={css(appStyles.title)}>Select Languages</Card.Title>
      <Card.Subtitle className="text-muted mb-2">
        Select languages that you want subtitling for:
      </Card.Subtitle>
      <Typeahead
        clearButton
        id="subtitling-language-dropdown"
        multiple={true}
        onChange={(selectedSubtitlingOptions) => {
          selectedSubtitlingOptions.forEach((option) => {
            if (!option.translationProfileID) {
              option.translationProfileID = translationProfiles.find(
                (profile) => profile.default
              )?.id;
            }
          });
          updateOrderOptions('Subtitling', { ...subtitlingOrderData, selectedSubtitlingOptions });
        }}
        options={firstOptionPerLanguage}
        labelKey={(item) => item.targetLanguage.fullName}
        placeholder="Search Languages"
        selected={selectedSubtitlingOrderOptions}
      />
      <SubtitlingOrderOptionsTable
        subtitlingOrderData={subtitlingOrderData}
        subtitlingOrderOptions={subtitlingOrderOptions}
        translationProfiles={translationProfiles}
        updateOrderOptions={updateOrderOptions}
      />
    </Wrapper>
  );
};

SubtitlingOrderOptions.propTypes = {
  disablePreprocessingOptions: PropTypes.bool,
  selectedServices: selectedServicesType.isRequired,
  skipContainer: PropTypes.bool,
  subtitlingOrderOptions: subtitlingOrderOptionsType.isRequired,
  translationProfiles: PropTypes.arrayOf(translationProfileType).isRequired,
  updateOrderOptions: PropTypes.func.isRequired,
  localizationProfiles: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ).isRequired,
};

export default SubtitlingOrderOptions;
