import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  subtitlingOrderOptionsType,
  translationProfileType,
} from '~/components/app/order_form/propTypes';
import OrderMore from '~/components/app/order_more/OrderMore';
import SubtitlingOrderOptions from '~/components/app/order_form/serviceOptions/SubtitlingOrderOptions';

const SubtitlingOrderSummary = ({ selectedOptions }) => {
  return (
    <div>
      <b>Subtitling Ordered</b>
      <ul>
        {selectedOptions.map((option) => (
          <li key={option.id}>{option.targetLanguage.fullName}</li>
        ))}
      </ul>
    </div>
  );
};

SubtitlingOrderSummary.propTypes = {
  selectedOptions: PropTypes.arrayOf(PropTypes.any),
};

const SubtitlingTipPanel = () => {
  return <p>Subtitling Tip Panel</p>;
};

const SubtitlingOrderMore = ({
  authToken,
  disablePreprocessingOptions,
  includeOnScreenTextValue,
  localizationProfileIdValue,
  localizationProfiles,
  mediaFiles,
  sdhValue,
  submissionUrl,
  subtitlingOrderOptions,
  translationProfiles,
}) => {
  const [selectedServices, setSelectedServices] = useState([
    {
      serviceType: 'Subtitling',
      orderOptions: {
        sdh: sdhValue,
        includeOnScreenText: includeOnScreenTextValue,
        localizationProfileId: localizationProfileIdValue,
        selectedSubtitlingOptions: [],
      },
    },
  ]);

  const subtitlingOrderData =
    selectedServices.find((service) => service.serviceType === 'Subtitling')?.orderOptions || {};
  const selectedSubtitlingOrderOptions = subtitlingOrderData?.selectedSubtitlingOptions || [];

  const updateOrderOptions = (serviceType, orderOption) => {
    const newSelectedServices = [...selectedServices];
    const serviceIndex = newSelectedServices.findIndex(
      (service) => service.serviceType === serviceType
    );
    if (serviceIndex !== -1) {
      newSelectedServices[serviceIndex] = {
        ...newSelectedServices[serviceIndex],
        orderOptions: orderOption,
      };
    }
    setSelectedServices(newSelectedServices);
  };

  const onSubmit = ({ setErrors, setSuccess }) => {
    const subtitlingOptions = {
      sdh: Boolean(subtitlingOrderData?.sdh ?? false),
      include_on_screen_text: Boolean(subtitlingOrderData?.includeOnScreenText ?? false),
      localization_profile_id: subtitlingOrderData?.localizationProfileId,
      subtitling_order_options: subtitlingOrderData?.selectedSubtitlingOptions?.map(
        (orderOption) => ({
          subtitling_order_option_id: orderOption.id,
          translation_profile_id: orderOption.translationProfileID,
        })
      ),
    };

    return fetch(submissionUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'X-CSRF-TOKEN': authToken,
      },
      body: JSON.stringify({
        media_file_ids: mediaFiles.map((file) => file.id),
        subtitling_options: subtitlingOptions,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setSuccess(true);
        } else {
          setErrors((errors) => [
            ...errors,
            'There was an error submitting your order. Please try again.',
          ]);
        }
      });
  };

  return (
    <OrderMore
      mainContent={
        <SubtitlingOrderOptions
          disablePreprocessingOptions={disablePreprocessingOptions}
          localizationProfiles={localizationProfiles}
          selectedServices={selectedServices}
          skipContainer={true}
          subtitlingOrderOptions={subtitlingOrderOptions}
          translationProfiles={translationProfiles}
          updateOrderOptions={updateOrderOptions}
        />
      }
      onSubmit={onSubmit}
      orderType="Subtitling"
      submissionDisabled={selectedSubtitlingOrderOptions.length === 0}
      summaryContent={<SubtitlingOrderSummary selectedOptions={selectedSubtitlingOrderOptions} />}
      tipPanelContent={<SubtitlingTipPanel />}
    />
  );
};

SubtitlingOrderMore.propTypes = {
  authToken: PropTypes.string.isRequired,
  disablePreprocessingOptions: PropTypes.bool.isRequired,
  includeOnScreenTextValue: PropTypes.bool.isRequired,
  localizationProfiles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  localizationProfileIdValue: PropTypes.number,
  mediaFiles: PropTypes.arrayOf(PropTypes.any).isRequired,
  sdhValue: PropTypes.bool.isRequired,
  subtitlingOrderOptions: subtitlingOrderOptionsType.isRequired,
  submissionUrl: PropTypes.string.isRequired,
  translationProfiles: PropTypes.arrayOf(translationProfileType).isRequired,
};

export default SubtitlingOrderMore;
