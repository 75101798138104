const serviceFields = `
  id,
  type,
  status,
  displayType,
  orderedAt,
  deadline,
  turnaroundLevel,
  completedAt,
  cancellable,
  rejection {
    reason,
    explanation,
    suggestion,
  },
  reformat,
  ... on AsrService {
    language { name },
    languages { name },
  },
  ... on AlignmentService {
    language { name },
    hasSourceText,
  }
  ... on AudioDescriptionImportService {
    level,
    speaker,
    speakingRate,
    state,
    adId,
  },
  ... on AudioDescriptionService {
    level,
    autoExtend,
    language { name },
    speaker,
    speakingRate,
    audioDescriptionId,
    canUpgrade,
    solutionType,
  },
  ... on AudioDescriptionNarrationService {
    level,
    language { name },
    audioDescriptionId,
    solutionType,
  },
  ... on VoiceOverAudioDescriptionService {
    level,
    language { name },
    audioDescriptionId,
    solutionType,
  },
  ... on AudioDescriptionReformatService {
    level,
    language { name },
    audioDescriptionId,
    solutionType,
  },
  ... on AudioDescriptionRevisionService {
    level,
    language { name },
    audioDescriptionId,
    solutionType,
  },
  ... on AudioDescriptionTranslationService {
    level,
    autoExtend,
    language { name },
    speaker,
    speakingRate,
    audioDescriptionId,
    canUpgrade,
  },
  ... on AudioMixingService {
    mixType,
    waitingForCustomer
    canChangeMindOnNoUpload
  },
  ... on CaptionEncodingService {
    encodingId,
    available,
    outputType,
    languages,
    expiry,
    studio,
  },
  ... on CaptionEmbeddingService {
    encodingId,
    available,
    outputType,
    languages,
    expiry,
    studio,
  },
  ... on CaptionImportService {
    language { name },
    importedFileType,
  },
  ... on DescriptiveTranscriptService {
    language { name },
  },
  ... on LiveAsrService {
    startTime,
    endTime,
    videoPlatform,
    duration,
  },
  ... on LiveCaptioningService {
    startTime,
    endTime,
    videoPlatform,
    duration,
  },
  ... on TranscriptionService {
    language { name },
    languages { name },
    currentTurnaroundLevel {
      id,
      name,
      surcharge{
        amount,
        unit
      },
    },
    upgradableTurnaroundLevels {
      id,
      name,
      deliveryDate,
      surcharge{
        amount,
        unit
      },
      withinBusinessHours
    },
    percentComplete @include(if: $showProgressBar),
  },
  ... on TranslationService {
    vendor,
    translationOrder { id },
    targetLanguage { name, fullName },
    serviceLevel,
    mediaFileId
  },
  ... on LocalizationService {
    vendor,
    translationOrder { id },
    targetLanguage { name, fullName },
    serviceLevel,
    mediaFileId
  },
  ... on LocalizationRevisionService {
    vendor,
    translationOrder { id },
    targetLanguage { name, fullName },
    serviceLevel,
    mediaFileId
  },
  ... on VideoClipCaptioningService {
    parentFile { id, name, duration },
    clipMap {
      score,
      matchedRegions {
        childStart,
        childEnd,
        parentStart,
        parentEnd,
        score,
      },
    },
  },
  ... on VideoClipCaptioningParentService {
    mediaFile { duration },
    childGroup {
      status,
      services {
        mediaFile { id, name },
        ... on VideoClipCaptioningService {
          clipMap {
            score,
            matchedRegions {
              childStart,
              childEnd,
              parentStart,
              parentEnd,
              score,
            },
          },
        }
      }
    }
  },
  ... on VendorTranscriptionService {
    language { name },
  },
  ... on VendorTranscriptionChooseForMeService {
    language { name },
  },
  ... on DubbingService {
    targetLanguage {
      fullName
    }
    waitingForCustomer
  },
  ... on DubbingRevisionService {
    targetLanguage {
      fullName
    }
    waitingForCustomer
  }
  ... on AIDubbingService {
    targetLanguage {
      fullName
    }
    translationType
  }
  ... on SubtitlingService {
    targetLanguage {
      fullName
    }
    turnaroundLevel
  }
`;

const fileActionFields = `
  download {enabled, errors},
  move { enabled, errors },
  addCheatSheet { enabled, errors },
  manageTags { enabled, errors },
  import { enabled, errors },
  archive { enabled, errors },
  publish { enabled,
    postbackVpi{enabled, name},
    postbackLinkedAccount{enabled, name},
    publishPlugin{enabled, name},
    publishAccessPlayer{enabled, name},
    adPostbackVpi{enabled, name},
    publishVideo{enabled, name},
    youtubePlugin{enabled, name},
    seoPlugin{enabled, name},
    lmsPlugin{enabled, name},
    ftpDeliveries{id, name},
  },
  order {
    enabled,
    upgradeToTranscription,
    liveUpgradeToTranscription,
    aiDubbing,
    audioDescription,
    audioMixing,
    broadcastScripts,
    captionPlacement,
    captionEncoding,
    dubbing,
    subtitling,
    amaraTranslation,
    translation,
    vicc,
    transcriptReview,
    voiceArtistAudioDescription,
    reformatServices {
      serviceName,
      serviceSlug
    },
    revisionServices {
      serviceName,
      serviceSlug
    }
  },
  replaceSourceEnabled,
  importTranslation {
    sourceLanguage,
    supportedLanguages { id fullName },
    supportedFormats
  },
`;

const fileDataFields = `id,
  projectId,
  name,
  archived,
  createdAt,
  duration,
  uploadedByUserName,
  uploadMethod,
  cheatSheet {
    content,
    pdf,
  },
  serviceGroups {
    name,
    status,
    services {${serviceFields}},
  },
  reprocessingAssets,
  assetsRemoved,
  hasSource,
  videoSource,
  proof,
  fileActions {${fileActionFields}}`;

const fileDataQuery = `query FileData($fileId: ID!, $showProgressBar: Boolean!) {
  file(id: $fileId) { ${fileDataFields} },
  project {
    enabledFeatures,
    contentPublishing,
    userTimeZone,
  }
}`;

const transcriptFields = `
  id,
  speakers {time, string},
  paragraphs,
  language {id, name, iso6391Code},
  lastEditedBy,
  lastEditedAt,
  wordCount,
  editUrl{enabled, url, name},
  revisions,
  formattedTranscript,
  editorLink`;

const transcriptListFields = `id,
  language {
    id,
    fullName,
    iso6391Code
  }`;

const transcriptQuery = `query Transcript($fileId: ID!, $transcriptId: ID) {
  file(id: $fileId) {
    transcript(transcriptId: $transcriptId) { ${transcriptFields} }
    transcriptList { ${transcriptListFields} }
  }
}`;

const streamQuery = `query Stream($fileId: ID!) {
  file(id: $fileId){
    stream {
      id,
      path
    }
  }
}`;

const encodedCaptionsQuery = `query EncodedCaptions($encodedCaptionId: ID!){
  encodedCaptions(encodedCaptionId: $encodedCaptionId){
      currentFontSize,
      verticalMarginEnabled,
      currentVerticalMargin
    }
}`;

const resourceConfigurationsQuery = `query ResourceConfigurations($names: [String!]){
  project {
    resourceConfigurations(names: $names){
      name,
      value
    }
  }
}`;

const adForProjectQuery = `query ADForProject{
  descriptionFormats {
    id,
    name,
    httpFormat
  }
}`;

const flipperFeatureEnabledQuery = `query FlipperFeatureEnabled($feature: String!){
  project{
    flipperFeatureEnabled(feature: $feature)
  }
}`;

const bwfDownloadEnabledQuery = `query BwfDownloadEnabled($audioDescriptionId: ID!){
  project {
    bwfDownloadEnabled(audioDescriptionId: $audioDescriptionId)
  }
}`;

export {
  bwfDownloadEnabledQuery,
  fileDataQuery,
  transcriptQuery,
  streamQuery,
  encodedCaptionsQuery,
  resourceConfigurationsQuery,
  adForProjectQuery,
  flipperFeatureEnabledQuery,
};
